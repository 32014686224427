/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {
  Box,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListSubheader,
  Typography,
  useMediaQuery,
  makeStyles,
  useTheme,
  withStyles,
  Avatar,
  Hidden
} from '@material-ui/core';
import Account from './Account';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import NavItem from './NavItem';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';

const ColorAppBar = withStyles({
  root: {
      backgroundColor : "#FFFFFF",
      boxShadow: 'none',
      borderBottom: '1px solid #e0e0e0'
  },
})(AppBar);

const Events = {
  items: [
    {
      title: 'Registrations',
      href: '/app/admin/events',
      icon: EventNoteOutlinedIcon,
      items: [
          {
            title: 'Events',
            href: '/app/admin/newevents',
            // icon: AccountBoxOutlinedIcon
          },
          {
            title: 'Participants',
            href: '/app/admin/neweventStudents',
            // icon: SwapVerticalCircleOutlinedIcon
          },
      ]
    },
    {
      title: 'Admin',
      href : '/app/admin/neweventDepartments',
      icon: WorkOutlineOutlinedIcon,
      items: [
        {
          title: 'Institutions',
          href: '/app/admin/neweventDepartments',
          // icon: AccountBoxOutlinedIcon
        },
        {
          title: 'Participants',
          href: '/app/admin/neweventAllParticipants',
          // icon: SwapVerticalCircleOutlinedIcon
        },
    ]
    },
  ]
};
const GuestEvents = {
  items: [
    {
      title: 'Registrations',
      href: '/app/admin/events',
      icon: EventNoteOutlinedIcon,
      items: [
          {
            title: 'Events',
            href: '/app/admin/newevents',
            // icon: AccountBoxOutlinedIcon
          },
          {
            title: 'Participants',
            href: '/app/admin/neweventStudents',
            // icon: SwapVerticalCircleOutlinedIcon
          },
      ]
    },
    
  ]
};
const Users = {

  items: [
    // {
    //   title: 'Schedule',
    //   href : '/app/admin/schedule',
    //   icon: ScheduleIcon,
      
    // },   
    {
      title: 'Grading',
      href : '/app/admin/academics',
      icon: ListAltIcon,
      
    },   
    {
      title: 'Courses',
      href: '/app/admin/courses',
      icon: DescriptionOutlinedIcon
    }, 
    {
      title: 'Calender',
      href: '/app/admin/calendar',
      icon: CalendarMonthIcon
    },
    {
      title: 'Fee Payments',
      href : '/app/admin/payments',
      icon: SwapVerticalCircleOutlinedIcon,    
    },
    {
      title: 'Certificates',
      href : '/app/admin/certificates',
      icon: DescriptionIcon,      
    },
    {
      title: 'Documents',
      href : '/app/admin/documents',
      icon: PostAddOutlinedIcon,      
    },
    // {
    //   title: 'Education',
    //   href : '/app/admin/education',
    //   icon: SchoolIcon,
    // },
    // {
    //   title: 'Placements',
    //   href : '/app/admin/placements',
    //   icon: WorkOutlineOutlinedIcon,
    // },
    // {
    //   title: 'Events',
    //   href : '/app/admin/events',
    //   icon: EventNoteOutlinedIcon,
    // },
    {
      title: 'Feedbacks',
      href : '/app/admin/feedback',
      icon: RateReviewOutlinedIcon,    
    },
    {
      title: 'Clubs',
      href : '/app/admin/groups',
      icon: ViewCompactIcon,
    },
    {
      title: 'Online Test',
      href : '/app/admin/test/list',
      icon: DriveFileRenameOutlineOutlinedIcon,    
    },
    // {
    //   title: 'Institution',
    //   href : '/app/admin/institution',
    //   icon: DriveFileRenameOutlineOutlinedIcon,
    // },
  
  ]
  
};

const Profiles = {
  items : [
    // {
    //   title: 'Home',
    //   href : '/app/admin/dashboard',
    //   icon: DashboardOutlinedIcon,
    // },
    {
      title: 'My Profile',
      href : '/app/admin/profile',
      icon: AccountBoxOutlinedIcon,
      // items : [
      //   {
      //     title: 'Profile',
      //     href : '/app/admin/profile',
      //     icon: AccountBoxOutlinedIcon,
      //   },
      //   {
      //     title: 'Technical Skills',
      //     href : '/app/admin/technicalskills',
      //     icon: AlbumOutlined,
      //   },
      //   {
      //     title: 'Internships',
      //     href : '/app/admin/internships',
      //     icon: AssessmentOutlinedIcon,
      //   },
      //   {
      //     title: 'Projects',
      //     href : '/app/admin/projects',
      //     icon: AssignmentTurnedInOutlinedIcon,
      //   },
      
      //   {
      //     title: 'Accomplishments',
      //     href : '/app/admin/accomplishments',
      //     icon: CardMembershipOutlinedIcon,
      //   },
      // ]
    },
  ]
}



function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        
      >
      
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
   
  },
  root1: {
    display: 'flex',
    paddingLeft : 256
  },
  desktopDrawer: {
    width: 256,
    //top: 64,
    height: '100%'
  
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: 
      {
      enteringScreen: 225,
      leavingScreen: 195,
      }
    })
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color : "#000000"
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));


function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

// const useRightStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex'
//   },
//   desktopDrawer: {
//     width: 70,
//     top: 64,
//     height: 'calc(100% - 64px)'
//   },

//   appBar: {
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     })
//   },

//   menuButton: {
//     marginRight: theme.spacing(2)
//   },
//   hide: {
//     display: 'none'
//   },

//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     })
//   },
  
//   contentShift: {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen
//     }),
//     marginLeft: 0
//   },
//   action: {
//     marginBottom: theme.spacing(1)
//   }
// }));

// const useLogoStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
    
//   },
//   desktopDrawer: {
//     width: 70,
//     top: 64,
//     height: 'calc(100% - 64px)'
//   },

//   appBar: {
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     })
//   },

//   menuButton: {
//     marginRight: theme.spacing(2)
//   },


//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     })
//   },
  
//   contentShift: {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen
//     }),
//     marginLeft: 70
//   },
//   action: {
//     marginBottom: theme.spacing(1)
//   }
// }));

const TopBar = ({ className, open, setOpen,isMobileNavOpen, openMobile, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user , image} = useAuth();

  const theme1 = useTheme();
  const mobileDevice = useMediaQuery(theme1.breakpoints.down('xs'));


  
  
  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  useEffect(() => {
    const handleStorageChange = (event) => {


      if (event.currentTarget.localStorage.drawer === "false") {
   
        setOpen(false);
      
      }
    };


    window.addEventListener('storage', handleStorageChange);

  });
  // window.addEventListener('localStorage', function(e) {
  //   console.log('storage fired');
  //   // this.fetchData();
  // });
  window.localStorage.setItem('test', '123');
  window.dispatchEvent(new Event('storage'));

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
 <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box mb = {2} mt ={1} ml={1} display = "flex" >
            
            <PlayCircleFilledWhiteOutlinedIcon />
            <Box mr = {1} />
            <Box >
          <Typography variant = "body1" color = "textPrimary">
            <strong>Student</strong> Module
            </Typography>
            </Box>
            </Box>
            {user.user_group === 'admin'?<> <List
              key={Events.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Events.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Events.items,
                pathname: location.pathname
              })}
            </List>
            <Box mt = {-1} />
            </>:user.user_group === 'guest'?<>
            <List
              key={GuestEvents.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {GuestEvents.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: GuestEvents.items,
                pathname: location.pathname
              })}
            </List>
           
            </>:<>
            <List
              key={Profiles.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Profiles.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Profiles.items,
                pathname: location.pathname
              })}
            </List>
            <Box mt = {-1} />
            <List
              key={Users.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Users.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Users.items,
                pathname: location.pathname
              })}
            </List>
            </>}
          
        </Box>
        <Divider />
        </PerfectScrollbar>

        <Box flexGrow={1} />
       
        <Divider />
       
    </Box>
  );





  return (
    <div className={classes.root}>
      { mobileDevice ? (
        <>
        
        
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          variant="persistent"
          anchor="left"
          open={false}
        >
          {content}
        </Drawer>
      </>
      ): (
        <>
        <CssBaseline />
  
        <ColorAppBar 
        className=
        {open ? (
        classes.root1
        ) : (
          classes.root
        )}
        {...rest} 
        >
          <Toolbar className={classes.toolbar}>
        
            {open === true && (
              <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
              >
              <MenuIcon fontSize = "large" />
              </IconButton>
            )}
           {open === false && (
              <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
              >
              <MenuOpenIcon fontSize = "large" />
              </IconButton>
            )}
         
           
            <Box
            display="flex"
            // alignItems="left"
            // component={ButtonBase}
          
            
            // className={classes.item}
          >
          
              <Avatar src={image} className={classes.avatar}></Avatar>
           <Box ml = {1} />
            <Hidden smDown>
            <Box ml={0.5}>
                <Box display="flex" justifyContent={'end'} alignItems={'end'} alignContent={'end'}>
                
                    <Typography variant="h6" textAlign="left" color="textPrimary">
                     {`${user['custom:organization_name']}`}
                    </Typography>
                
                  <Box mr={1} />

                
                </Box>
                <Box display="flex" mt={-0.2}>
                 
                    <Typography
                      variant="body2"
                      textAlign="left"
                      // color="inherit"
                      color="textSecondary"
                    >
                      {/* {stream.stream_code ? stream.stream_code : ''} -{' '}
                      {stream.stream_name ? stream.stream_name : ''} */}
                       {`${user['custom:institution_name']}`}
                    </Typography>
               
                </Box>
              </Box>
              </Hidden>
          </Box>
            <Box ml={2} flexGrow={1} />
            {/* <Search />  */}
          
           <Notifications />
            
            <Box ml={2}>
              <Account />
            </Box>
          </Toolbar>
        </ColorAppBar>
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {content}
        </Drawer>
        </>
      
      )}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
